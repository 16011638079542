import { environment } from "src/environments/environment"

const base = environment.base;
export const api = {
    invite: base+"/startup/getPromoText/", //GET
    Login: base+"/user/mobile/login", //POST
    adminLogin: base+"/admin/login", //POST
    validateAdminOtp : base+'/admin/otp', //POST
    googleLogin : base+"/user/social",
    ResendOtp: base +"/user/resendOTP", //POST
    ValidateOtp: base +"/user/mobile/otp", //POST
    ValidateCaretakerOtp : base+'/user/sso/otp', //POST
    RegisterUser: base +"/user/registerUserPayment", //POST
    ForgotPassword: base +"/user/forgotPassword", //POST
    CountriesList: base +"/startup/getAllCountries", //GET
    ResetPassword: base +"/user/password/reset", //POST
    UserProfile: base +"/user/details", //GET
    uploadUserPicture : base + '/user/profilePic', //POST
    deleteUserPicture: base+'/user/deleteProfilePic', //DELETE
    ChangePassword : base+"/user/verifyPassword", // POST
    SavePassword : base+"/user/savePassword", //POST
    GetStates: base + "/startup/getAllStates/", //GET
    change2FaSetting : base+"/user/2faSettings", //PUT
    careTaker : base + "/caretaker/", //POST
    updateUserProfile : base+`/user/`, //PUT
    VerifyResetUrl:  base +"/user/verifyResetUrl/", //GET
    removeToken: base + "/user/removeToken", //POST
    getKycToken : base +"/user/kyc/token", //GET
    getKycLegacyToken : base+"/user/kyc/legacy/token", //GET
    updateRaKycStatus: base+"/recovery-agent/raKYC", //PUT
    getAlerts : base+"/user/alerts", //GET PUT
    deleteAlert : base+"/user/alerts", // DELET
    updateKYC : base+"/user/kycUpdate", //POST
    updateUserKycStatus : base+'/user/recovery/kycUpdate', //POST
    updateInitiateRecoverKyc : base+'/user/initiate/kycUpdate', //POST
    getKycDetails : base+'/user/kyc/details', //GET
    guardianOnBoarding : base+'/user/guardianonboarding', //PUT
    userOnBoarding : base+'/user/useronboarding', //PUT
    auditTrail : base+'/reports/myActivity',//POST
    faq : base+'/user/crypto/faq', //GET
    //Will APIs
    saveWill : base+'/cryptowill', //POST
    updateWill : base+'/cryptowill', //PUT
    saveExecutors : base+'/cryptowill/executor/bulk', //POST
    getWillList : base+'/cryptowill',//GET
    finalizeWill : base+'/cryptowill/generateWill', //GET

    //recovery agents
    recoveryAgentCreate : base + '/recovery-agent/create', //POST
    recoveryAgentUpdate : base + '/recovery-agent/update', //PUT
    getRecoveryAgents : base + '/recovery-agent/', //GET
    getMeAsRaList :  base + '/user/recovery-agent/', //GET
    finalizeRa : base + '/recovery-agent/finalizeRA', //POST
    recoveryAgentReject : base + '/user/recovery-agent/reject', //POST
    clocrRecoveryAgentReject : base + '/legacy-service/recovery-agent/reject', //PUT
    secreats :  base + '/recovery-agent/EnrollSecret', //GET
    resendReq : base + '/recovery-agent/resent', //POST
    resendClocrReq : base+'/legacy-service/recovery-agent/resent', //PUT
    resendClocrRecoveryReq : base+'/legacy-service/recovery/resent',//PUT
    clientAppStatus :base + '/user/clientapp/status',//GET
    deleteRecoveryAgents :base + '/recovery-agent/delete?id=', //DELETE
    keyRecoverRequest :  base + '/recovery-agent/SecretKeyRecoverRequest',
    myRequestToRespond :  base + '/user/secretKey/request', //POST
    requestInitiatedResent :  base + '/user/secretKey/resent', //POST
    rejectSecretRecoverRequest : base + "/user/secretKey/reject", //POST
    rejectSelfSecretRecoverRequest : base + "/legacy-service/recovery/reject", //PUT
    secretKeyDetails : base +'/recovery-agent/secretKeyDetails?recovery_agent_id=', //GET
    saveInheritance : base+'/user/inheritance', //POST
    saveInheritanceTrustee : base+'/user/trustee', //POST
    getContracts : base+'/user/inheritance', //GET
    deleteContract : base+'/user/inheritance',// DELETE
    updateContract: base+'/user/inheritance',//PUT
    updateTrusteeEmail : base+'/user/trustee', // PUT DELETE
    getSecretValutDashboardData : base+'/user/secretKey/dashboard', //GET
    thersholdCount: base+'/user/thershold', //GET
    agreeVaultAgreement: base+'/user/agreement', //PUT
    getWillData : base+'/cryptowill', //GET
    deleteExecutor : base+'/cryptowill/executor', //DELETE
    deleteNominee : base+'/cryptowill/nominees', //DELETE
    saveBenificiaries: base+'/cryptowill/nominees/bulk', //POST
    saveWallet : base+'/cryptowill/wallet', //PUT
    createDisbursementRequest: base+'/disbursement/crypto', //POST
    getDisbursementRequests: base+'/disbursement/crypto', //GET
    getDisbursementReasons: base+'/startup/getAllDisbursementReasons', //GET
    getDisbursementUsers : base+'/disbursement/users', //GET
    getDisbursementNotes : base+'/disbursement/crypto/', //GET
    saveDisbursementNotes : base+'/disbursement/crypto/', //POST
    saveDisbursementFiles : base+'/disbursement/crypto/doc/', //POST
    getDisbursementFiles : base+'/disbursement/crypto/doc/',//GET
    getAllDisbursementRequests : base+'/disbursement/crypto/request', //GET
    acceptDisbursementRequest : base+'/disbursement/crypto/accept',//PUT
    rejectDisbursementRequest : base+'/disbursement/crypto/reject',//PUT
    getAdminDisbursementFiles : base+'/admin/crypto/doc/',//GET
    getAdminDisbursementRequests : base+'/admin/crypto/disbursement', //GET
    approveAdminDisbusementRequest : base+'/admin/crypto/disbursement/approve', //PUT
    reviewAdminDisbusementRequest : base+'/admin/crypto/disbursement/review', //PUT
    markAsReadyForDisbursement: base+'/admin/crypto/disbursement/ready-for-disbursement',//PUT
    getDisbursementDetails: base+'/admin/crypto/disbursement/request',//PUT
    getDisbursementRecordDetails: base+'/admin/crypto/disbursement/details',//GET
    saveOnboardingInformation : base+'/user/useronboarding',//PUT
    resetRecovery : base + "/recovery-agent/toughbox/resetRecovery", //POST
    getTimeLineData: base+'/disbursement/crypto/audit', //GET
    // updateClientAppStatus: base+'/user/clientapp/status', //PUT
    //contacts
    contacts : base+'/caretaker', //GET DELETE POST
    setRole: base + '/user/role', //PUT
    dashboard: base + '/user/secretKey/dashboard/v1', //GET
    clients: base + '/cryptowill/beneficiary/clients', //GET
    willDetails: base + '/cryptowill/beneficiary/wills', //GET
    allWills: base + '/cryptowill/beneficiary/wills/all',//GET
    trusteeClients: base + '/user/trustee/clients', //GET
    vault: base + '/user/trustee/vaults', //GET
    allVaults: base + '/user/trustee/vaults/all',//GET
    userExists: base + '/user/exists', //POST
    register: base + '/user/registerUser', //POST PUT
    subscriptionList: base + '/crypto/payment/products', //GET
    kycList: base + '/crypto/payment/kyc/products', //GET
    kycDetails: base + '/crypto/payment/kyc/details', //GET
    creditLimit: base + '/crypto/payment/creditLimit', //GET
    stripeSession: base + '/crypto/payment/stripeSession', //POST
    stripeSessionKyc: base + '/crypto/payment/kyc/stripeSession', //POST
    timezone: base + '/user/timezone', //PUT
    cancelSubscription: base + '/crypto/payment/cancelSubscription', //POST
    resumeSubscription: base + '/crypto/payment/resumeSubscription',
    subscriptionHistory: base + '/crypto/payment/subscriptionHistory', //GET
    checkDevicePermission: base+'/recovery-agent/ra_device/verify', //POST
    deleteSecretKey: base + '/user/secret_vault', //DELETE
    trusts: base + '/user/trust', //GET PUT POST DELETE
    getSelfRecoveryKeys : base+'/legacy-service/keys', //GET
    emergencyBinder: base + '/user/emergencyBinder', //GET
    createRA : base+'/legacy-service/recovery-agent/create', //POST
    getRAs: base+'/legacy-service/recovery-agent', //GET
    getRecoveryGuardian : base+'/legacy-service/recovery-agent/request', //GET
    getSelfSecretRequests : base+'/legacy-service/recovery/request',  //GET
    cancelRecoveryRequest: base+'/legacy-service/cancelRecovery', //PUT
    updateSecretRecoverStatus : base + '/recovery-agent/toughbox/kyc/initateUserRecoveryRequest', //GET
    getRewards : base + '/user/reward-points', //GET
    getWalletIFrameUrl: base +'/user/getfront-api/iframe-url', //GET
    getAccountHoldings: base +'/user/getfront-api/data', //GET
    brokerInfo: base +'/cryptowill/broker', //GET, POST
    updateClientAppStatus: base+'/user/client/update', // GET
    getPromoDetails: base + '/startup/crypto/getPromoText' // GET

}
