// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base : "https://uat.cryptolegacy.ai/api/v1",
  s3: "https://s3.amazonaws.com/",
  clientAppUrl : "https://s3.amazonaws.com/clocrdev/msi/CryptoLegacy.msi",
  macClientAppUrl : "https://s3.amazonaws.com/clocrdev/mac/CryptoLegacy.dmg",
  macClientAppUrlx64 : "https://s3.amazonaws.com/clocrdev/mac/x64/CryptoLegacy.dmg",
  get_front_end_clientId : "8b5a40e4-3537-4899-aa94-08db18ea2a62"
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
