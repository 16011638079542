import { Injectable } from '@angular/core';
import { api } from "./../crypto-constants/api";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PopoverController, ToastController } from '@ionic/angular';
import { ReCaptchaV3Service } from "ngx-captcha";
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { AppState } from '../store/state';
import { Store } from '@ngrx/store';
import { SET_CONTACTS } from '../store/actions/contacts.action';
import { environment } from 'src/environments/environment';
import { closeOutline } from 'ionicons/icons';
// import { } from '../../assets/images/download-locations/chrome-browser.png';
import { SET_PROFILE } from '../store/actions/userProfile.action';
import { map } from 'rxjs/operators';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  sitekey = "6Lczf30UAAAAAKglPyC-bdjnGM5cD2jORkfTKUx5";
  userData: any;
  isAdminUser = false;
  indiaKycWorkflowId = 'workflow_xnIi3gl_input';
  usaKycWorkflowId = 'workflow_t9U9Kem';
  uaeKycWorkflowId = 'workflow_uae';
  ausKycWorkflowId = 'workflow_aus';
  ukKycWorkflowId = 'workflow_uk';
  kycAllowedCountries = [
    "India",
    "United States",
    "Australia",
    "United Arab Emirates",
    "United Kingdom"
  ]
  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private store: Store<AppState>,
    private popover: PopoverController
  ) {
    this.store.select('userProfile').subscribe((data) => {
      if (data && data.userProfile) {
        this.userData = data.userProfile;
        this.isAdminUser = this.userData.isAdmin;
      }
    });

 
  }

  savedBenificiariesList: any = [];
  willsList: any = [];
  private isLoading$$ = new BehaviorSubject<any>(false);
  isLoading$ = this.isLoading$$.asObservable();
  public isRaOrGuardianUpdated$$ = new BehaviorSubject<any>(false);
  isRaOrGuardianUpdated$ = this.isRaOrGuardianUpdated$$.asObservable();
  public isKYCupdated$$ = new BehaviorSubject<any>(false);
  isKYCupdated$ = this.isKYCupdated$$.asObservable();
  public redirectSubject$$ = new Subject<any>();
  redirectSubject$ = this.redirectSubject$$.asObservable();
  public setGuardianTabSubject$$ = new Subject<any>();
  setGuardianTabSubject$ = this.setGuardianTabSubject$$.asObservable();
  public showOnboardingSubject$$ = new Subject<void>();
  showOnboardingSubject$ = this.showOnboardingSubject$$.asObservable();
  public userRoleSet$$ = new Subject<{ role?: string }>();
  // userRoleSet$ = this.userRoleSet$$.asObservable();
  savedBinders: any[] = [];
  public navigationUserRoleSet$$ = new Subject<{ role?: string }>();
  navigationUserRoleSet$ = this.navigationUserRoleSet$$.asObservable();
  public dashboardRoleSetForHeader$$ = new Subject<{ role?: string }>();
  dashboardRoleSetForHeader$ = this.dashboardRoleSetForHeader$$.asObservable();

  async showToasterMessage(message: string, position: 'top' | 'middle' | 'bottom', toasterType: string, downloadPopup = false) {
    const toast = await this.toastController.create({
      message: message,
      duration: downloadPopup ? 15000 : 1500,
      position: position,
      buttons: downloadPopup ? [
        {
          side: 'end',
          icon: closeOutline as any
        }
      ] : [],
      cssClass: downloadPopup ? [toasterType + '-toaster', 'download-popup-toaster'] : [toasterType + '-toaster', 'toaster-message'],
      color: downloadPopup ? '' : toasterType,
    });

    if (downloadPopup) {

      let arrowPlacement = (toasterType === 'chrome-downloaded-popup') ? 'left' : 'right';

      const div = document.createElement('div');
      div.style.maxWidth = '85%';
      div.style.float = (arrowPlacement === 'left') ? 'right' : 'left';
      const p = document.createElement('p');
      p.textContent = 'Crypto Legacy Secure Shell is being downloaded.'
      // toast.shadowRoot?.querySelector('.toast-message')?.appendChild(p);
      div?.appendChild(p);
      const p1 = document.createElement('p');
      p1.textContent = 'Check your Downloads section.';
      // toast.shadowRoot?.querySelector('.toast-message')?.appendChild(p1);
      div?.appendChild(p1);
      const p2 = document.createElement('p');
      p2.textContent = 'Install the application after the download is complete.';
      // toast.shadowRoot?.querySelector('.toast-message')?.appendChild(p2);
      div?.appendChild(p2);
      const p3 = document.createElement('p');
      p3.textContent = 'You can close this window now.';
      // toast.shadowRoot?.querySelector('.toast-message')?.appendChild(p3);
      div?.appendChild(p3);
      toast.shadowRoot?.querySelector('.toast-message')?.appendChild(div);

      // const div1 = document.createElement('div');
      const image = document.createElement('img');
      image.setAttribute('src', '../../assets/images/download-locations/' + toasterType + '.png');
      image.setAttribute('height', '160');
      image.setAttribute('class', 'browser-location-img');
      image.setAttribute('width', '50');
      image.style.objectFit = 'contain';
      image.setAttribute('alt', 'download location');
      // image.style.borderRadius = '50%';
      // image.style.marginRight = '15px';
      image.style.float = arrowPlacement;
      toast.shadowRoot?.querySelector('.toast-message')?.appendChild(image);
    }


    await toast.present();
  }

  setLoading(isLoading: boolean) {
    this.isLoading$$.next(isLoading);
  }

  getKycWorkflowId(country: string) {
    console.log(country, 'country')
    switch (country) {
      case 'India':
        return this.indiaKycWorkflowId
        break;
      case 'United States' :
        return this.usaKycWorkflowId;
        break;
      case 'Australia' :
        return this.ausKycWorkflowId;
        break;
      case 'United Arab Emirates' :
        return this.uaeKycWorkflowId;
        break;
      case 'United Kingdom' :
        return this.ukKycWorkflowId;
        break;
      default:
        return this.indiaKycWorkflowId
        break;
    }
  }

  getKycWorkflowIdforInitiateRecover(country: string) {
    console.log(country, 'country')
    switch (country) {
      case 'India':
        return "workflow_xnIi3gl_input"
        break;
      case 'United States' :
        return "workflow_t9U9Kem_input";
        break;
      case 'Australia' :
        return "workflow_aus_input";
        break;
      case 'United Arab Emirates' :
        return "workflow_uae_input";
        break;
      case 'United Kingdom' :
        return "workflow_uk_input";
        break;
      default:
        return "workflow_xnIi3gl_input"
        break;
    }
  }

  isKycAllowed(country: string) {
    return this.kycAllowedCountries.indexOf(country);
  }

  generaterecaptchaToken() {
    let promise = new Promise((reslove, reject) => {
      this.reCaptchaV3Service.execute(this.sitekey, 'submit', (token: any) => {
        reslove(token);
      }, {
        useGlobalDomain: false
      });
    });
    return promise;
  }

  checkDevicePermission(uuid: any, deviceId: any) {
    return this.httpClient.post(api.checkDevicePermission, { "deviceId": deviceId, "uuid": uuid, "os": "windows" })
  }

  isLoggedIn(): Observable<boolean> {
    let user = sessionStorage.getItem('user');
    if (user) {
      return of(true);
    } else {
      return of(false);
    }
  }

  isAdmin() {
    let isAdmin = JSON.parse(sessionStorage.getItem('user'))?.isAdmin;
    let isLoggedInAsAdmin = JSON.parse(localStorage.getItem('isLoggedInAsAdmin'));
    return ((this.isAdminUser || isAdmin) && isLoggedInAsAdmin);
  }

  getCountriesPromise() {
    let promise = new Promise((reslove, reject) => {
      this.httpClient.get(api.CountriesList).toPromise()
        .then((response: any) => {
          if (response['success']) {
            response['data'] = response['data'].map((obj: any) => {
              obj['filterKey'] = obj['country'] + " " + obj['iso2'] + " " + obj['countryCode'];
              return obj;
            });
            response['data'] = response['data'].sort((a: any, b: any) => {
              let x = a.country.toLowerCase();
              let y = b.country.toLowerCase();
              if (x < y) { return -1; }
              if (x > y) { return 1; }
              return 0;
            });
            reslove(response.data);
          }
        })
    });
    return promise;
  }

  getStates(countryId: number): Observable<any> {
    return this.httpClient.get(api.GetStates + countryId);
  }

  handleError(error: any) {
    if (error && error?.error?.message) {
      this.showToasterMessage(error?.error?.message, "top", "danger");
    } else if (error) {
      this.showToasterMessage(error, "top", "danger");
    }
    // else {
    //   this.showToasterMessage("Something went wrong!", "top", "danger");
    // }
  }

  getKycToken(transId: string,from:string) {
    return this.httpClient.get(api.getKycToken + "?transId=" + transId+"&from="+from);
  }

  downloadApplication(OS : String,version: string) {
    let className = this.detectBrowserName();
    let url;
    if (OS === "WIN") {
      url = (this.userData && this.userData.msi_url) ? this.userData.msi_url : environment.clientAppUrl;
    } else if(OS === "ARM64") {
      url = (this.userData && this.userData.mac_url) ? this.userData.mac_url : environment.macClientAppUrl;
    }
    else if(OS === "X86_64") {
      url = (this.userData && this.userData.mac_url_x64) ? this.userData.mac_url_x64 : environment.macClientAppUrlx64;
    }
    let position: any = className === 'chrome-downloaded-popup' ? 'bottom' : 'top';
    window.open(url, "_blank");
    window.open('../../assets/files/instructions.pdf', '_blank');
    this.showToasterMessage('', position, className, true);
    this.updateClientAppStatus({ "installed_version":version,
    "last_status_result":"INSTALLED",
    "app_downloaded_date": new Date(),
    "client_app_downloaded" : 'YES',
    "last_status_result_date": new Date()
    });
  }


  getOsName(): string {
    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
      return "MAC"
    } else {
      return "WIN"
    }
  }

  updateClientAppStatus(data: any) {
    this.httpClient.put(api.updateClientAppStatus, 
      data).subscribe(() => {
        this.getUserDetails().subscribe((data) => {
          this.store.dispatch( { type : SET_PROFILE , userProfile : data['data'] } );
        })
      });
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1 || agent.indexOf('edg') > -1:
        return 'edge-downloaded-popup';
      // case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      //   return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'firefox-downloaded-popup';
      // case agent.indexOf('trident') > -1:
      //   return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox-downloaded-popup';
      case agent.indexOf('safari') > -1:
        return 'safari-downloaded-popup';
      default:
        return 'firefox-downloaded-popup';
    }
  }


  getContacts() {
    this.httpClient.get(api.careTaker).toPromise()
      .then((response: any) => {
        if (response && response['success']) {
          // this.showToasterMessage("Contact created", "top","success");
          this.store.dispatch({ type: SET_CONTACTS, contacts: response['data'] });
        }
        else {
          console.log(response)
          this.handleError(response['message']);
        }
      })
      .catch(error => {
        console.log(error)
        this.handleError(error?.error?.message)
      })

  }

  getSavedWills() {
    return this.httpClient.get(api.getWillList);
    // .subscribe((response :any) => {
    //   if(response.success) {
    //     this.store.dispatch( { type : SET_CONTACTS , contacts : response['data'] } );
    //   }
    // }
    // )
  }

  getSavedBenificiaries() {
    let promise = new Promise((resolve, reject) => {
      if (this.savedBenificiariesList && this.savedBenificiariesList.length) {
        resolve(this.savedBenificiariesList);
      } else {
        this.getSavedWills().subscribe((wills: any) => {
          // console.log(wills.data);
          this.willsList = wills.data;
          if (wills && wills.data && wills.data.length) {
            wills.data.forEach((will: any, index: number) => {
              if (will.careTakerDetails && will.careTakerDetails.length) {
                will.careTakerDetails.forEach((element: any) => {
                  let checkForDuplicate = this.savedBenificiariesList.find((el: any) => el.user_email === element.user_email);
                  if (!checkForDuplicate) {
                    let obj = {
                      user_first_name: element.user_first_name,
                      user_last_name: element.user_last_name,
                      user_email: element.user_email,
                      relationship: element.relationship,
                      user_caretaker_id: element.user_caretaker_id,
                      wills: [will.name]
                    };
                    this.savedBenificiariesList.push(obj);
                  } else {
                    checkForDuplicate.wills.push(will.name);
                  }
                });
              }
              if (wills.data.length === index + 1) {
                resolve(this.savedBenificiariesList)
              }
            });
          } else {
            resolve([]);
          }
        })
      }
    });
    return promise;
  }



  startKyc(from:any) {
    if (this.userData.user_country) {
      if (this.isKycAllowed(this.userData.user_country.country) !== -1) {
        let transactionId = "SELF_"+this.userData.uuid;
        this.getKycToken(transactionId,from).subscribe((data: any) => {
          if (data.success) {
            // let workflowId = this.userData.user_country.country === 'India' ? 'workflow_xnIi3gl' : 'workflow_t9U9Kem';
            let workflowId = this.getKycWorkflowId(this.userData.user_country.country);
            let token = data.data;
            const hyperKycConfig = new window.HyperKycConfig(token, workflowId, transactionId);
            window.HyperKYCModule.launch(hyperKycConfig, this.handler);
          }
        },
          (error: any) => {
            let message = (error && error.error && error.error.message) ? error.error.message : 'Unable to start KYC';
            this.showToasterMessage(message, 'top', 'danger');
          })
      } else {
        this.showToasterMessage('KYC available only for India and United States', 'top', 'danger');
      }
    } else {
      this.showToasterMessage('Please update your country before start KYC', 'top', 'danger');
    }
  }

  handler = (HyperKycResult: any) => {
    // let _that = this;
    switch (HyperKycResult.status) {
      case "user_cancelled":
        this.updateKycStatus();
        this.showToasterMessage('KYC cancelled', 'top', 'danger');
        break;
      case "error":
        this.updateKycStatus();
        this.showToasterMessage('Unable to complete KYC', 'top', 'danger');
        break;
      case "auto_approved":
        this.updateKycStatus();
        this.showToasterMessage('KYC completed', 'top', 'success');
        break;
      case "auto_declined":
        this.updateKycStatus();
        this.showToasterMessage('KYC declined', 'top', 'danger');
        break;
      case "manually_approved":
        this.updateKycStatus();
        this.showToasterMessage('KYC declined', 'top', 'danger');
        break;
      case "needs_review":
        this.updateKycStatus();
        this.showToasterMessage('KYC needs review', 'top', 'danger');
        break;
    }
  }




  updateKycStatus() {
    this.httpClient.post(api.updateKYC, {}).subscribe((data) => {
      this.isKYCupdated$$.next(true);
      this.httpClient.get(api.UserProfile).toPromise()
        .then((response: any) => {
          if (response && response.success) {
            this.store.dispatch({ type: SET_PROFILE, userProfile: response['data'] })
          }
        })
    });
  }

  getKycDetails() {
    return this.httpClient.get(api.getKycDetails);
  }

  setUserRole(body: any) {
    return this.httpClient.put(api.setRole, body).pipe(map(response => {
      return response;
    }));
  }

  getDashboardData() {
    return this.httpClient.get(api.dashboard).pipe(map(response => {
      return response;
    }));
  }

  getPastGuardiansRequests() {
    return this.httpClient.get(api.getMeAsRaList, { params: { requestType: 'PAST_REQUEST' } }).pipe(map(response => {
      return response;
    }));
  }

  getRecentGuardiansRequests() {
    return this.httpClient.get(api.getMeAsRaList, { params: { requestType: 'RECENT_REQUEST' } }).pipe(map(response => {
      return response;
    }));
  }

  getRecoveryGuardiansRequests() {
    return this.httpClient.get(api.getRecoveryGuardian).pipe(map(response => {
      return response;
    }));
  }

  getSecretRecoveryRequests() {
    return this.httpClient.get(api.getSelfSecretRequests).pipe(map(response => {
      return response;
    }));
  }

  getClients() {
    return this.httpClient.get(api.clients).pipe(map(response => {
      return response;
    }));
  }

  getWillDetails(clientId: number) {
    return this.httpClient.get(api.willDetails, { params: { id: clientId } }).pipe(map(response => {
      return response;
    }));
  }

  getAllWills() {
    return this.httpClient.get(api.allWills).pipe(map(response => {
      return response;
    }));
  }

  getClientAppStatus(): Observable<any> {
    return this.httpClient.get(api.clientAppStatus).pipe(map(response => {
      return response;
    }));
  }

  getThresholdCount(): Observable<any> {
    return this.httpClient.get(api.thersholdCount).pipe(map(response => {
      return response;
    }));
  }

  getRecentSecretKeysRequests(): Observable<any> {
    return this.httpClient.get(api.myRequestToRespond, { params: { requestType: 'RECENT_REQUEST' } }).pipe(map(response => {
      return response;
    }));
  }

  getPastSecretKeysRequests(): Observable<any> {
    return this.httpClient.get(api.myRequestToRespond, { params: { requestType: 'PAST_REQUEST' } }).pipe(map(response => {
      return response;
    }));
  }

  getTrusteeClients(): Observable<any> {
    return this.httpClient.get(api.trusteeClients).pipe(map(response => {
      return response;
    }));
  }

  getSecretVaultDetails(id: number): Observable<any> {
    return this.httpClient.get(api.vault, { params: { id } }).pipe(map(response => {
      return response;
    }));
  }

  getAllVaults(): Observable<any> {
    return this.httpClient.get(api.allVaults).pipe(map(response => {
      return response;
    }));
  }

  getUserDetails(): Observable<any> {
    return this.httpClient.get(api.UserProfile).pipe(map(response => {
      return response;
    }));
  }

  isUserExists(body: any): Observable<any> {
    return this.httpClient.post(api.userExists, body).pipe(map(response => {
      return response;
    }));
  }

  registerUser(body: any): Observable<any> {
    return this.httpClient.put(api.register, body).pipe(map(response => {
      return response;
    }));
  }

  getGuardians(): Observable<any> {
    return this.httpClient.get(api.getRecoveryAgents).pipe(map(response => {
      return response;
    }));
  }

  getPlanList(): Observable<any> {
    return this.httpClient.get(api.subscriptionList).pipe(map(response => {
      return response;
    }));
  }

  getKycProducts(): Observable<any> {
    return this.httpClient.get(api.kycList).pipe(map(response => response));
  }

  getKYCDetails(): Observable<any> {
    return this.httpClient.get(api.kycDetails).pipe(map(response => response));
  }

  getCreditLimitDetails(): Observable<any> {
    return this.httpClient.get(api.creditLimit).pipe(map(response => response));
  }

  createStripeSession(priceId: any, redirectUrl: any): Observable<any> {
    return this.httpClient.post(api.stripeSession, { priceId , redirectUrl }).pipe(map(response => response));
  }

  createKycStripeSession(priceId: any, redirectUrl: any): Observable<any> {
    return this.httpClient.post(api.stripeSessionKyc, { priceId, redirectUrl }).pipe(map(response => response));
  }

  setUserTimezone(timeZone: string): Observable<any> {
    return this.httpClient.put(api.timezone, { timeZone }).pipe(map(response => response));
  }

  cancelSubscription(): Observable<any> {
    return this.httpClient.post(api.cancelSubscription, null).pipe(map(response => response));
  }

  resumeSubscription(): Observable<any> {
    return this.httpClient.post(api.resumeSubscription, null).pipe(map(response => response));
  }

  getSubscriptionHistoryData(): Observable<any> {
    return this.httpClient.get(api.subscriptionHistory).pipe(map(response => response));
  }

  deleteSecretKey(id: any): Observable<any> {
    return this.httpClient.delete(api.deleteSecretKey, { params: { id } }).pipe(map(response => response));
  }

  getTrusts(): Observable<any> {
    return this.httpClient.get(api.trusts).pipe(map(response => response));
  }

  saveTrust(body: any): Observable<any> {
    return this.httpClient.post(api.trusts, body).pipe(map(response => response));
  }

  updateTrust(body: any): Observable<any> {
    return this.httpClient.put(api.trusts, body).pipe(map(response => response));
  }

  deleteTrust(id: number): Observable<any> {
    return this.httpClient.delete(api.trusts, { params: { trust_id: id } }).pipe(map(response => response));
  }

  addBinder(data: any) {
    let promise = new Promise((resolve, reject) => {
      this.savedBinders.push(data);
      resolve(this.savedBinders);
    });
    return promise;
  }

  updateBinder(data: any, index: number) {
    let promise = new Promise((resolve, reject) => {
      if (this.savedBinders.length) {
        this.savedBinders[index] = data;
      }
      resolve(this.savedBinders);
    });
    return promise;
  }

  getBinderData(): Observable<any> {
    return this.httpClient.get(api.emergencyBinder).pipe(map(response => response));
  }

  // Payment APIS
  getPromoCodeDetails(promocode: string){
    return this.httpClient.get(api.getPromoDetails +'/'+promocode).pipe(map(response => response));
  }
}
